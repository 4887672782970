<template>
  <div class="release-add-or-edit-box">
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onClickLeft" />
    <van-form @submit="onSubmit" class="form">
      <div class="list-box">
        <div class="filed-box">
          <van-field
            v-model="releaseForm.title"
            name="标题"
            label="标题"
            required
            placeholder="请输入公众号标题"
            maxlength="20"
            :rules="[{ required: true, message: '' }]"
          />
        </div>
        <div class="filed-box editors-box">
          <van-field
            v-model="releaseForm.companyLuminousPoint"
            name="公司亮点"
            label="公司亮点"
            disabled
            required
            readonly
            :rules="[{ required: true, message: '公司亮点' }]"
          />
          <quill-editors
            @ChangeText="
              (text) => {
                releaseForm.companyLuminousPoint = text;
              }
            "
            :showData="releaseForm.companyLuminousPoint"
            :title="true"
            :maxLength="50"
          />
        </div>
        <div class="type-box">
          <p>选择发布的职位</p>
          <ul>
            <li
              v-for="(item, i) in allPositions"
              :key="i"
              :class="{ active: item.checked }"
              @click="typeCheck(item, i)"
            >
              {{ item.text }}
              <img v-if="item.checked" src="../../assets/release/checked.png" />
            </li>
          </ul>
        </div>
      </div>

      <div class="tip" @click="goPositionAddOrEdit">没有要发布的职位？立即添加新的职位</div>
      <div class="btn-box">
        <van-button round block type="info" native-type="submit">保存</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
export default {
  name: 'releaseAddOrEdit',
  data() {
    return {
      title: '公众号发布', // 个人信息修改
      allPositions: [],
      releaseForm: {
        id: null,
        title: '',
        companyLuminousPoint: '',
        publishPosition: [],
        positionIds: [],
      },
      userInfo: null
    };
  },
  async mounted() {
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    }
    let res = await this.getPositions();
    if (res.code === 200) {
      this.allPositions = res.data.map((el) => {
        el.text = el.name;
        el.value = el.id;
        el.checked = false;
        return el;
      });
    }
    let { query } = this.$route;
    if (query.id) {
      this.getReleaseDetail(query.id);
    }
  },
  methods: {
    getPositions() {
      return this.$http.get('/selfhelpOfficialAccounts/positionSelfhelp', {
        companyId: this.userInfo.companyVO.id,
        approveStatus: 'APPROVE_NORMAL',
      });
    },
    getReleaseDetail(id) {
      this.$http
        .get('/selfhelpOfficialAccounts/publish/' + id)
        .then((res) => {
          if (res.code === 200) {
            this.releaseForm = res.data;
            this.allPositions.forEach((el) => {
              if (this.releaseForm.positionIds.includes(el.id)) {
                el.checked = true;
              }
            });
            this.$forceUpdate();
          }
        });
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    goPositionAddOrEdit() {
      this.$router.push('/positionMgt/positionAddOrEdit');
    },
    typeCheck(item, i) {
      // if (i === 0) {
      //   console.log(this.allPositions[0]);
      //   this.allPositions[0].checked = !this.allPositions[0].checked;
      //   this.allPositions.forEach((el, j) => {
      //     if (j > 0) el.checked = this.allPositions[0].checked;
      //   });
      // } else {
      //   console.log(this.allPositions[i]);

      // }
      this.allPositions[i].checked = !this.allPositions[i].checked;
      if (!this.allPositions[i].checked) {
        this.allPositions[0].checked = false;
      }
    },
    onSubmit() {
      let arr = [],
        nameArr = [];
      this.allPositions.forEach((el) => {
        if (el.checked) {
          arr.push(el.value);
          nameArr.push(el.name);
        }
      });
      if  (arr.length === 0) {
        this.$toast('请选择发布的职位');
        return false
      }
      this.releaseForm.positionIds = arr;
      this.releaseForm.publishPosition = nameArr;
      this.$dialog
        .confirm({
          message: `你该次申请发布公众号的职位有【${this.releaseForm.publishPosition.join(
            ','
          )}】，请再次确认，一个月只能提交一次公众号发布`,
        })
        .then(() => {
          if (this.releaseForm.id) {
            this.$http
              .put(`/selfhelpOfficialAccounts/publish/${this.releaseForm.id}`, this.releaseForm)
              .then((res) => {
                if (res.code === 200) {
                  this.$toast('修改发布成功');
                  this.$router.push('/release/index');
                }
              });
          } else {
            this.$http
              .post('/selfhelpOfficialAccounts/publish', this.releaseForm)
              .then((res) => {
                if (res.code === 200) {
                  this.$toast('新增发布成功');
                  this.$router.push('/release/index');
                }
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.release-add-or-edit-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .form {
    flex: 1;
    padding: 0 15px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .list-box {
    margin: 20px 0 40px 0;
    display: flex;
    flex-direction: column;
    .filed-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ebedf0;
      > .van-cell {
        flex: 1;
      }
      > .van-cell::after {
        border-bottom: none;
      }
      img {
        width: 77px;
        height: 23px;
      }
      ::v-deep .van-field__control {
        text-align: right;
      }
    }
    .editors-box {
      display: initial;
      ::v-deep .van-field__body input {
        display: none;
      }
      .editor {
        height: 150px;
      }
    }
    ::v-deep .van-field__label span {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    ::v-deep .van-field--disabled .van-field__label {
      color: #646566;
    }
    .type-box {
      p {
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin: 20px 0 0 0;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          padding: 5px 8px;
          background: #f7f7f7;
          border-radius: 2px;
          margin-right: 10px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-top: 15px;
        }
        .active {
          background: #e8f0fe;
          border-radius: 2px;
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #206cfe;
          position: relative;
          img {
            position: absolute;
            height: 12px;
            width: 12px;
            top: 0;
            right: 0;
          }
        }
      }
    }
  }
  ::v-deep .van-field__error-message {
    text-align: right;
    font-size: 14px;
  }
  .btn-box {
    margin-top: 40px;
    padding: 0 4px;
  }
  .tip {
    margin-top: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #206cfe;
    text-align: center;
  }
}
</style>
